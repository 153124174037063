import moment from "moment";
import { JSONValue } from "./types";

export type ValueFunction<Value, Arg> = (arg: Arg) => Value;
export type ValueOrFunction<Value, Arg> = Value | ValueFunction<Value, Arg>;

/**
 * check if the input is a fuction or not
 * @param valOrFunction the input value can be anything
 * @returns { boolean }
 */

export const isFunction = <Value, Arg>(
  valOrFunction: ValueOrFunction<Value, Arg>
): valOrFunction is ValueFunction<Value, Arg> =>
  typeof valOrFunction === "function";

/**
 * this function can be used for more inputs (which support function - which returns value - or the value itself )
 * @param valOrFunction a function or a value
 * @param arg arguement for the function mode (can be undefined)
 * @returns the raw value (which is the result of the function or the value itself)
 */
export const resolveValue = <Value, Arg>(
  valOrFunction: ValueOrFunction<Value, Arg>,
  arg: Arg
): Value => (isFunction(valOrFunction) ? valOrFunction(arg) : valOrFunction);

/**
 * clean the object from null or undefeind properties
 * @param obj the object (which can contain some values as undefined or null)
 * @param { boolean } removeEmptyString if it's true it would remove the string values if they're empty
 * @returns cleaned object
 */
export function cleanObj(
  obj: {
    // eslint-disable-next-line @typescript-eslint/ban-types
    [x in string]: JSONValue | Function;
  },
  removeEmptyString = false
) {
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (
      value === null ||
      value === undefined ||
      (removeEmptyString && typeof value === "string" && value.length === 0)
    ) {
      // eslint-disable-next-line no-param-reassign
      delete obj[key];
    }
  });
  return obj;
}

/**
 * is the input string value a valid email or not
 * @param { string } email input email address
 * @returns
 */
export const isValidEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const requiredString = (input: string | undefined) => {
  if (input === undefined || input.length <= 0) {
    return false;
  }
  return true;
};

export const isPasswordSecureEnough = (
  pass: string
): {
  isSecure: boolean;
  message?: string;
  level: number;
} => {
  const checkRegxWithErrorMessage = (regx: RegExp, errorMessage: string) => {
    if (!regx.test(pass)) {
      return errorMessage;
    }
    return undefined;
  };

  const checkLengthOfPass = () => {
    if (!pass || pass.length < 8) {
      return "Your password must be at least 8 characters";
    }
    return undefined;
  };

  const errorMessages = [
    checkLengthOfPass(),
    checkRegxWithErrorMessage(/[A-Z]/, "You password must contains uppercase"),
    checkRegxWithErrorMessage(/[a-z]/, "You password must contains lowercase"),
    checkRegxWithErrorMessage(/\d/, "You password must contains number"),
  ].filter((item) => item !== undefined);

  // Calculate the level
  let level = 0;
  if (errorMessages.length === 0) {
    if (pass.length > 12) {
      level = 4; // Very secure
    } else {
      level = 3; // Good security
    }
  } else if (errorMessages.length === 1) {
    level = 2; // Somewhat secure
  } else {
    level = 1; // Weak password
  }

  return {
    isSecure: errorMessages.length === 0,
    level,
    message: errorMessages.length > 0 ? errorMessages[0] : undefined,
  };
};

/**
 * convert seconds in number into mm:ss or HH:mm:ss string formats
 * @param { number } seconds in seconds!
 * @returns { [string, string] } minutes and seconds
 */
export const presentableDuration = (seconds: number): [string, string] => {
  return [
    Math.floor(seconds / 60).toString(),
    moment.utc(seconds * 1000).format("ss"),
  ];
};

/**
 * Greatest common divisor (gcd) of integers
 * @param { number[] } arr array of numbers
 * @returns { number }
 */
export const gcd = (arr: number[]): number => {
  const gcdForTwoNumbers = (x: number, y: number) => (!y ? x : gcd([y, x % y]));
  return arr.reduce((a, b) => gcdForTwoNumbers(a, b));
};

/**
 * clamp the value between min and max
 * @param { number } value
 * @param { number } min
 * @param { number } max
 * @returns { number } clapped value of input
 */
export const clamp = (value: number, min: number, max: number) => {
  if (min > max) {
    throw Error(`clamping: min: ${min} is more than max: ${max}!`);
  }
  return Math.min(Math.max(value, min), max);
};

/**
 * get timestamp value
 * @returns { number } timestamp
 */
export const now = () => {
  return Date.now();
};

/**
 * get a range of numbers from some number to another one
 * @param {number} from start point
 * @param {number} to end point
 * @param {number} step steps between the `from` and `to` params
 * @returns {Array<number>} array of the numbers between `from` and `to` by steps of `step`
 */
export const range = (from: number, to: number, step: number) => {
  if (to - from < 0 || step === 0) {
    return [];
  }
  return Array.from(
    { length: Math.floor((to - from) / step) + 1 },
    (_, i) => from + i * step
  );
};

/**
 * InRange type is a type for restricting the numbers in a range Type :)
 */
type Enumerate<
  N extends number,
  Acc extends number[] = []
> = Acc["length"] extends N
  ? Acc[number]
  : Enumerate<N, [...Acc, Acc["length"]]>;

export type IntRange<F extends number, T extends number> =
  | Exclude<Exclude<Enumerate<T>, Enumerate<F>>, T>
  | T;

type Predicate<T> = (value: T[keyof T] | undefined, key: keyof T) => boolean;

/**
 * transform an object (excluding or removing properties) base on the keys and values
 * @param obj the object value (or partial instance of object)
 * @param predicate the predicate function to make decision for the pair of key/value (included or not included)
 * @returns Partial object of type obj input value type
 */
export const transform = <T extends object>(
  obj: Partial<T>,
  predicate: Predicate<T>
): Partial<T> => {
  return Object.keys(obj).reduce((memo, key) => {
    const targetKey = key as keyof T;
    if (predicate(obj[targetKey], targetKey)) {
      return {
        ...memo,
        [key]: obj[targetKey],
      };
    }
    return memo;
  }, {} as Partial<T>);
};

export const omit = <T extends object>(
  obj: T,
  keys: Array<keyof T>
): Partial<T> => {
  return transform(obj, (_value, key: keyof T) => !keys.includes(key));
};

export const pick = <T extends object>(
  obj: Partial<T>,
  keys: Array<keyof T>
): Partial<T> => {
  return transform(obj, (_value, key: keyof T) => keys.includes(key));
};

export const getAgeFor = (birthdate: string) => {
  return moment().diff(birthdate, "years");
};

export const limitDecimal = (value: number, numberOfDecimals = 2) => {
  const tenPow = 10 ** numberOfDecimals;
  return (Math.round((value + Number.EPSILON) * tenPow) / tenPow)
    .toString()
    .replace(".", ",");
};

export const isEmpty = (obj: string | null | undefined | object) => {
  // Check if obj is null or undefined
  if (obj === null || obj === undefined) {
    return true;
  }

  if (
    typeof obj === "string" &&
    (obj.length === 0 || obj.trim().length === 0)
  ) {
    return true;
  }

  // Check if obj is an array and has no elements
  if (Array.isArray(obj) && obj.length === 0) {
    return true;
  }

  // Check if obj is an object and has no own enumerable properties
  if (typeof obj === "object" && Object.keys(obj).length === 0) {
    return true;
  }

  return false;
};

export const times = (n: number) =>
  Array.from({ length: n }, (_, index) => index);

/**
 * generate random hex string with the selected length (default 16)
 * @param {number} length length of the hex string
 * @returns { string } generated hex string
 */
export const generateRandomHex = (length = 16) => {
  const characters = "0123456789abcdef";

  const result = Array.from({ length }, () => 0).reduce((acc) => {
    const randomIndex = Math.floor(Math.random() * characters.length);
    return acc + characters.charAt(randomIndex);
  }, "");

  return result;
};

export const cloneDeep: <T>(objectToClone: T) => T = (objectToClone) =>
  JSON.parse(JSON.stringify(objectToClone));

export const isNonEmptyString = (input: string | undefined | null): boolean => {
  return !!input && typeof input === "string" && input.length > 0;
};

export const parseBoolean = (input: string | boolean) => {
  return String(input).toLowerCase() === "true";
};

export const uploadFile = ({
  url,
  file,
  headers,
  fieldName,
  onProgress,
}: {
  file: File;
  url: string;
  headers?: { [x in string]: string };
  fieldName: string;
  onProgress: (progress: number) => void;
}) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.upload.addEventListener("progress", (e) =>
      onProgress(e.loaded / e.total)
    );
    xhr.addEventListener("load", () =>
      resolve({ status: xhr.status, body: xhr.responseText })
    );
    xhr.addEventListener("error", () =>
      reject(new Error("File upload failed"))
    );
    xhr.addEventListener("abort", () =>
      reject(new Error("File upload aborted"))
    );

    xhr.open("POST", url, true);
    if (headers) {
      for (const key of Object.keys(headers)) {
        xhr.setRequestHeader(key, headers[key]);
      }
    }

    const formData = new FormData();
    formData.append(fieldName, file, file.name);
    xhr.send(formData);
  });

export type OperatingSysName = "Windows Phone" | "Android" | "iOS";

export const getMobileOperatingSystem = (): OperatingSysName | undefined => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return undefined;
};
